import queryString from 'query-string'
const Cookies = require('js-cookie')

document.addEventListener('turbolinks:load', () => {
  const params = queryString.parse(window.location.search)

  // Store the campaign in a cookie.
  if (params.campaign) {
    Cookies.set('dd_campaign', params.campaign, { expires: 365 })
  }
})
