import anchorScroll from 'anchor-scroll'

document.addEventListener('turbolinks:load', () => {
  document.querySelector('.js-toggle-nav').addEventListener('click', e => {
    e.preventDefault()
    document.querySelector('.header').classList.toggle('header--nav-open')
  })

  anchorScroll.init({
    updateUrl: true,
    offset: -40,
    ease: 'inQuad',
    duration: 1000,
    selector: '.js-smooth-scroll',
  })
})
