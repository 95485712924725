import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['message']

  dismiss(event) {
    const element = event.target.closest('[data-flash-target="message"]')
    const flashId = element.getAttribute('data-flash-id')
    if (flashId) {
      Cookies.set(flashId, true, { expires: 3650 })
    }
    element.remove()
  }
}
